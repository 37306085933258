import React from "react";
import NavBar from "../Components/NavBar";
import HomeMaster from "../Components/HomeMaster";
import Eventscard from "../Components/Eventscard";
import { Col, Container, Image, Row } from "react-bootstrap";
import Sportscard from "../Components/SportsCard";
import { Link } from "react-router-dom";
import AndraFlood from "./AndraFlood";

const Home = () => {
  return (
    <div>
      <NavBar />
      <HomeMaster />
      {/* <AndraFlood /> */}
      <section style={{ backgroundColor: "#e6e7ea" }}>
        <Container fluid>
          <Row className="g-0 py-4">
            <Col lg={9} md={9} sm={12} xs={12}>
              <Eventscard />
              <Sportscard />
            </Col>
            <Col lg={3} md={3} className="mt-3">
              <div className="d-flex justify-content-center">
                <small>Advertisement</small>
              </div>
              <Link to="https://forms.gle/YZ8rr4Dzpwi4R3An7">
                <Image
                  className="mx-auto d-block pt-2 border"
                  src="https://ayg.s3.us-east-2.amazonaws.com/bharat/Beige+and+Brown+Illustrative+Krishna+Janmashtami+Greeting+Instagram+Post+(1).gif"
                  fluid
                />
              </Link>
              {/*
              <Link to="https://forms.gle/YZ8rr4Dzpwi4R3An7">
                <Image
                  className="mx-auto d-block pt-2 border"
                  src="https://ayg.s3.us-east-2.amazonaws.com/bharat/Beige+And+Red+Bold+Ganesh+Chaturthi+Instagram+Post.gif"
                  fluid
                />
              </Link>
              <Link to="https://forms.gle/YZ8rr4Dzpwi4R3An7">
                <Image
                  className="mx-auto d-block pt-2 border"
                  src="https://ayg.s3.us-east-2.amazonaws.com/bharat/Video+Wishing+Happy+Ganesh+Chaturthi+Whatsapp+Story.jpg"
                  fluid
                />
              </Link> */}
              <Link to="https://forms.gle/YZ8rr4Dzpwi4R3An7">
                <Image
                  className="mx-auto d-block pt-2 border"
                  src="https://ayg.s3.us-east-2.amazonaws.com/bharat/Beige+and+Maroon+traditional+Minimal+Guru+Purnima+Greeting+Instagram+Post.gif"
                  fluid
                />
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default Home;
